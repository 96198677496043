<template>
  <v-card>
    <!-- TITULO TIPO DE PLAN -->
    <h3 class="pl-5 pt-6" style="font-weight: normal">
      Plan {{ data_cotizacion.nombre_tipo_plan }}
    </h3>
    <!-- FIN TITULO TIPO DE PLAN -->
    <br />

    <v-card-text class="px-5">
      <div style="font-size: 14px">
        <!-- NUMERO DE AFILIADOS -->
        <v-row>
          <v-col><strong>Numero Afiliados:</strong></v-col>
          <v-col class="text-right">{{
            data_cotizacion.numero_personas
          }}</v-col>
        </v-row>
        <!-- FIN DE NUMERO DE AFILIADOS -->

        <!-- PERIODO DE PAGO -->
        <v-row class="mt-n5">
          <v-col><strong>Periodo de Pago:</strong></v-col>
          <v-col class="text-right">{{
            data_cotizacion.nombre_periodo_pago
          }}</v-col>
        </v-row>
        <!-- FIN PERIODO DE PAGO -->

        <v-divider class="mt-4 mb-4"></v-divider>

        <!-- VALOR POR AFILIADO -->
        <v-row>
          <v-col><strong>Valor por Afiliado:</strong></v-col>
          <v-col v-if="!cargando_cotizacion" class="text-right"
            >${{ valor_por_afiliado | moneda }}</v-col
          >
          <!-- CAMPO DE CARGA  -->
          <v-col v-else class="d-flex justify-end"
            ><div class="skeleton-loader"></div
          ></v-col>
          <!-- FIN DE CAMPO DE CARGA  -->
        </v-row>
        <!-- FIN VALOR POR AFILIADO -->

        <!-- VALOR POR AFILIACION WEB -->
        <v-row class="mt-n5">
          <v-col cols="8"><strong>Descuento por Afiliación Web:</strong></v-col>
          <v-col cols="4" v-if="!cargando_cotizacion" class="text-right"
            >${{ descuento_afiliacion_web | moneda }}</v-col
          >
          <!-- CAMPO DE CARGA  -->
          <v-col v-else class="d-flex justify-end"
            ><div class="skeleton-loader" style="width: 65%"></div
          ></v-col>
          <!-- FIN DE CAMPO DE CARGA  -->
        </v-row>
        <!-- FIN VALOR POR AFILIACION WEB -->

        <v-divider class="mt-4 mb-4"></v-divider>

        <!-- TOTAL AFILIACION WEB -->
        <v-row style="color: #43a047">
          <v-col><strong>Total Afiliación Web:</strong></v-col>
          <v-col v-if="!cargando_cotizacion" class="text-right"
            ><strong>${{ total_afiliacion_web | moneda }}</strong></v-col
          >
          <!-- CAMPO DE CARGA  -->
          <v-col v-else class="d-flex justify-end"
            ><div class="skeleton-loader"></div
          ></v-col>
          <!-- FIN DE CAMPO DE CARGA  -->
        </v-row>
        <!-- FIN TOTAL AFILIACION WEB -->

        <v-divider class="mt-4 mb-4"></v-divider>

        <div>Su siguiente factura llegaría por un valor de</div>

        <!-- SIGUIENTE FACTURA -->
        <v-row class="mt-1">
          <v-col><strong>Siguiente Factura:</strong></v-col>
          <v-col v-if="!cargando_cotizacion" class="text-right"
            ><strong>${{ siguiente_factura | moneda }}</strong></v-col
          >
          <!-- CAMPO DE CARGA  -->
          <v-col v-else class="d-flex justify-end"
            ><div class="skeleton-loader"></div
          ></v-col>
          <!-- FIN DE CAMPO DE CARGA  -->
        </v-row>
        <!-- FIN SIGUIENTE FACTURA -->

        <v-divider class="mt-4 mb-4"></v-divider>

        <!-- TEXTO Y CHECKBOX TERMINOS Y CONDICIONES -->
        <v-row
          ><v-col cols="2"
            ><v-simple-checkbox
              :ripple="false"
              small
              v-model="checkbox_terminos_condiciones"
              color="primary"
            ></v-simple-checkbox
          ></v-col>
          <v-col cols="10" class="ml-n8" style="padding-top: 13px">
            <label style="font-size: 14px"
              >Acepto los <a>términos</a> de uso y políticas de tratamiento de
              datos personales.</label
            ></v-col
          ></v-row
        >
        <!-- FIN TEXTO Y CHECKBOX TERMINOS Y CONDICIONES -->

        <!-- BOTON CONTINUAR -->
        <v-row>
          <v-col class="d-flex justify-center"
            ><v-btn
              color="primary"
              :disabled="!validarContinuarAfiliacion"
              class="mb-3"
              @click="$emit('continuar-afiliacion', {valor_por_afiliado:valor_por_afiliado,id_alianza:id_alianza})"
              >Continuar Afiliación</v-btn
            ></v-col
          >
        </v-row>
        <!-- FIN BOTON CONTINUAR -->
      </div></v-card-text
    >
  </v-card>
</template>

<script>
//Se importan los servicios para obtener las tarifas
import afiliacionService from "../services/afiliacionService";

export default {
  //Variable que va contener todos los parametros para cotizar la afiliacion
  props: ["data_cotizacion"],
  //Al ejecutar el ciclo de vida created se ejecuta la funcion cotizar
  created() {
    this.cotizar();
  },
  data: () => ({
    //---------------- DATOS MODAL ----------------

    //Variables utilizadas en la vista
    checkbox_terminos_condiciones: false,
    valor_por_afiliado: 0,
    id_alianza:0,
    descuento_afiliacion_web: 0,
    total_afiliacion_web: 0,
    siguiente_factura: 0,

    //Variables de carga y si hay error
    error_cotizar: false,
    cargando_cotizacion: false,
  }),
  computed: {
    /**
     * Funcion que activa el boton de continuar afilacion
     *
     * @returns {boolean} resultado de la validacion de todas las condiciones
     */
    validarContinuarAfiliacion() {
      //Se valida que no se esten trayendo los datos de la cotizacion (cargando_cotizacion == false)
      //Se valida que no haya un error al momento de haber traido los datos de la cotizacion (error_cotizar == false)
      //Se valida que haya aceptado los terminos y condiciones (checkbox_terminos_condiciones == true)
      if (
        !this.cargando_cotizacion &&
        !this.error_cotizar &&
        this.checkbox_terminos_condiciones
      ) {
        return true;
      }
    },
  },
  methods: {
    /**
     * Funcion que cotiza una afiliacion dependiendo de los datos recibidos por los props del componente
     */
    async cotizar() {
      //Se activa el icono de carga en los campos correspondientes a las tarifas de cotizacion
      this.cargando_cotizacion = true;

      //Se llama al servicio que trae la tarifa y el descuento dependiendo de los parametros recibidos por los props(data_cotizacion)
      await afiliacionService
        .generarCotizacion(this.data_cotizacion)
        .then((response) => {
          //Se asigna el valor de la tarifa por afiliado
          this.valor_por_afiliado = response.data.tarifa;
          this.id_alianza = response.data.id_alianza;
      

          //Se asigna el valor del descuento que se aplica por la afiliacion web
          this.descuento_afiliacion_web = response.data
            .descuento_afiliacion_web_sobre_cada_beneficiario
            ? this.data_cotizacion.numero_personas *
              response.data.descuento_afiliacion_web
            : response.data.descuento_afiliacion_web;

          //Se obtiene el valor total de la afiliacion con el descuento por ser afiliacion web
          this.total_afiliacion_web =
            this.data_cotizacion.numero_personas *
              this.valor_por_afiliado *
              this.data_cotizacion.cantidad_meses -
            this.descuento_afiliacion_web;

          //Se calcula el valor de la proxima factura pero no se le aplica el descuento
          this.siguiente_factura =
            this.data_cotizacion.numero_personas *
            this.valor_por_afiliado *
            this.data_cotizacion.cantidad_meses;
        })
        .catch((e) => {
          console.log(e);
          error_cotizar = true;
        });

      //Se desactiva el icono de carga en los campos correspondientes a las tarifas de cotizacion
      this.cargando_cotizacion = false;
    },
  },
};
</script>

<style scoped>
.skeleton-loader {
  border-radius: 3px;
  width: 40%;
  height: 80%;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
</style>
