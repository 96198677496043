<template>
  <div>
    <v-row class="d-flex justify-center align-center">
      <v-col cols="12" sm="8" md="5" lg="5">
        <v-card class="mt-6 mx-lg-7 ma-lg-5">
          <v-toolbar dark color="blue darken-3" height="40">
            <router-link
              to="/"
              v-if="this.$store.state.token_afiliacion_asesores != null"
              ><v-icon>mdi-arrow-left</v-icon></router-link
            >
            <v-spacer></v-spacer>
            <h3>Cotiza tu Afiliación</h3>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <v-form ref="FormularioCotizarAfiliacion">
              <!-- SELECT TIPO DE PLAN -->
              <v-col cols="12" class="mb-n8">
                <label class="mr-3"
                  >Tipo de Plan<span class="campo_obligatorio">*</span></label
                ><br />
                <v-select
                  dense
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  placeholder="Tipo de Plan"
                  :items="tipos_planes"
                  :rules="[(v) => !!v || 'Debe Seleccionar un tipo plan.']"
                  item-text="tpcnom"
                  item-value="tpccod"
                  v-model="tipo_plan"
                >
                </v-select>
              </v-col>
              <!-- FIN SELECT TIPO DE PLAN -->

              <!-- SELECT NUMERO DE PERSONAS -->
              <v-col cols="12" class="mb-n8">
                <label class="mr-3">Número de Personas</label>
                <v-text-field
                  dense
                  outlined
                  type="number"
                  :rules="[
                    (v) => v > 0 || 'Debe ser un numero entero mayor que 0.',
                  ]"
                  placeholder="Numero de personas"
                  v-model="numero_personas"
                ></v-text-field>
              </v-col>
              <!-- FIN SELECT NUMERO DE PERSONAS -->

              <!-- SELECT PERIODO DE PAGO -->
              <v-col cols="12" class="mb-n8">
                <label class="mr-3"
                  >Periodo de Pago<span class="campo_obligatorio"
                    >*</span
                  ></label
                ><br />
                <v-select
                  dense
                  outlined
                  placeholder="Periodo de pago"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[
                    (v) => !!v || 'Debe Seleccionar un periodo de pago.',
                  ]"
                  :items="periodos_pago"
                  item-text="texto_periodo_pago"
                  item-value="id"
                  v-model="periodo_pago"
                >
                </v-select>
              </v-col>
              <!-- SELECT FIN PERIODO DE PAGO -->

              <!-- CHECKBOX ALIANZA -->
              <!-- <v-col cols="12">
                <v-checkbox
                  v-model="checkbox_alianza"
                  :label="`Alianza`"
                  class="mt-1"
                ></v-checkbox>
                <div v-if="checkbox_alianza">
                  <v-select
                    dense
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    placeholder="Alianza"
                  >
                  </v-select>
                </div>
              </v-col> -->
              <!-- FIN CHECKBOX ALIANZA -->

              <!-- ACLARACIONES Y BOTON COTIZAR -->
              <v-col cols="12" :class="checkbox_alianza ? 'mt-n3' : 'mt-n6'">
                <strong>Medio</strong><br />

                Elija el medio de pago por el cual desea seguir cancelando su
                factura.<br /><br />

                <strong>Nota: </strong> El primer pago se puede realizar por
                tarjeta crédito o tarjeta debito, PSE, Efecty, Baloto.<br /><br />

                <v-select
                  dense
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  placeholder="Medio de pago"
                  :items="medios_pago"
                  v-model="medio_pago"
                  :rules="[(v) => !!v || 'Debe seleccionar un metodo de pago']"
                  item-text="v"
                  item-value="id_medio_pago"
                >
                </v-select>

                <div class="d-flex justify-center">
                  <v-btn
                    class="mt-7"
                    color="primary"
                    @click="cotizarTarifaAfiliacion"
                    >Hacer Cotización</v-btn
                  >
                </div>
              </v-col>
            </v-form>
            <!-- ACLARACIONES Y BOTON COTIZAR -->
          </v-card-text>
          <v-card-actions> </v-card-actions>

          <!-- MODAL COTIZAR AFILIACION -->
          <v-dialog v-model="modal_cotizar_afiliacion" width="370">
            <!-- Se condiciona el contenido del modal para que siempre se este renderizando la vista del modal y se ejecute el ciclo de vida created en el modal -->
            <ModalCotizarAfiliacion
              v-if="modal_cotizar_afiliacion"
              :data_cotizacion="data_cotizacion_prop"
              @continuar-afiliacion="
                $router.push({ path: `/afiliacion/${tipo_plan}` })
              "
            />
          </v-dialog>
          <!-- FIN MODAL COTIZAR AFILIACION -->
        </v-card>
      </v-col>
      <div id="test"></div>
    </v-row>
  </div>
</template>

<script>
//Se importan los servicios para obtener las tarifas
import afiliacionService from "./services/afiliacionService";

//Se importa el modal para mostrar el resultado de la cotizacíon
import ModalCotizarAfiliacion from "./components/ModalCotizarAfiliacion.vue";

export default {
  name: "SimularTarifa",
  data: () => ({
    //----------- COTIZADOR ----------------

    //Tipo de Planes
    tipos_planes: [],
    tipo_plan: 1,

    //Numero de personas
    numero_personas: 1,

    //Alianza
    checkbox_alianza: false,
    periodo_pago_alianza: 0,

    //Periodo de pago
    periodos_pago: [],
    periodo_pago: 0,

    //Metodos de pago
    medios_pago: [],
    medio_pago: 0,

    //-------------- MODALES --------------

    //Modal cotizacion afiliacion
    modal_cotizar_afiliacion: false,

    //Variable que va contener todos los datos de la cotizacion en la vista para pasarselos al modal
    data_cotizacion_prop: {
      nombre_tipo_plan: "",
      id_plan: null,
      numero_personas: 1,
      nombre_periodo_pago: "",
      id_periodo_pago: null,
      cantidad_meses: null,
      nombre_medio_pago: "",
      id_medio_pago: null,
      debito_automatico: false,
      es_alianza: false,
      es_asesor: false,
    },
  }),
  components: {
    ModalCotizarAfiliacion,
  },
  async created() {
    //http://127.0.0.1:8002
    //Se activa el icono de carga de la pantalla
    this.$store.state.loading = true;

    //Se traen todos los datos relacionados de la vista en una sola peticion para optimizar tiempos de carga
    await afiliacionService
      .getDatosVistaCotizarTarifa()
      .then((response) => {
        this.tipos_planes = response.data.tipos_planes;
        this.medios_pago = response.data.medios_pago;
      })
      .catch((e) => {
        this.$swal.fire({
          title: "Error al traer los metodos pago",
          icon: "error",
          position: "center",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
        console.log(e);
      });

    await afiliacionService
      .getPeriodosPago()
      .then((response) => {
        this.periodos_pago = response.data;
        if (response.data.length > 0) {
          response.data.forEach((periodo_pago_iterar) => {
            console.log(periodo_pago_iterar);
            Object.assign(periodo_pago_iterar, {
              texto_periodo_pago:
                periodo_pago_iterar.periodo_pago +
                (periodo_pago_iterar.descuento_periodo_pago != null
                  ? " (" + periodo_pago_iterar.descuento_periodo_pago + ")"
                  : ""),
            });
          });
        }
      })
      .catch((error) => {
        this.$swal.fire({
          title: "Error al traer los periodos de pago",
          icon: "error",
          position: "center",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
        console.log(error);
      });

    //Se desactiva el icono de carga de la pantalla
    this.$store.state.loading = false;
  },
  methods: {
    /**Funcion que obtiene y valida todos los valores para cotizar la afiliacion */
    cotizarTarifaAfiliacion() {
      //Se valida que el formulario si este completo
      if (this.$refs.FormularioCotizarAfiliacion.validate()) {
        //Se asignan los datos del tipo de plan a los datos de cotizacion
        this.data_cotizacion_prop.id_plan = this.tipo_plan;
        this.data_cotizacion_prop.nombre_tipo_plan = this.obtenerNombreTipoPlan(
          this.tipo_plan
        );

        //Se asigna la cantidad de personas a afiliar a los datos de cotizacion
        this.data_cotizacion_prop.numero_personas = this.numero_personas;

        //Se asignan los datos del periodo de pago a los datos de cotizacion
        var data_periodo_pago = this.obtenerDatosPeriodoPago(this.periodo_pago);

        this.data_cotizacion_prop.id_periodo_pago = this.periodo_pago;

        this.data_cotizacion_prop.nombre_periodo_pago =
          data_periodo_pago.nombre_periodo_pago;

        this.data_cotizacion_prop.cantidad_meses =
          data_periodo_pago.cantidad_meses;

        //Se asignan los datos del medio de pago a los datos de cotizacion
        this.data_cotizacion_prop.id_medio_pago = this.medio_pago;

        var data_medio_pago = this.obtenerDatosMedioPago(this.medio_pago);

        this.data_cotizacion_prop.nombre_medio_pago =
          data_medio_pago.nombre_medio_pago;

        this.data_cotizacion_prop.debito_automatico =
          data_medio_pago.debito_automatico;

        this.modal_cotizar_afiliacion = true;
      }
    },

    /**Funcion que retorna el nombre de un plan de afiliacion apartir de su id
     *
     * @param {number} id_plan id del plan de afiliacion al que se la buscar el nombre
     * @returns {string} nombre del plan de afiliacion
     */
    obtenerNombreTipoPlan(id_plan) {
      /**Variable que va retornar el nombre del plan en el caso de ser encontrado */
      var nombre_tipo_plan = "N/a";

      //Se valida que si hallan planes de afiliacion
      if (this.tipos_planes.length > 0) {
        //Se itera sobre los planes de afiliacion existentes
        this.tipos_planes.forEach((tipo_plan) => {
          //Se compara el id del plan de afiliacion sobre el que se esta iterando con el que fue recibio por los parametros de la funcion
          if (tipo_plan.tpccod == id_plan) {
            nombre_tipo_plan = tipo_plan.tpcnom;
          }
        });
      }
      //Se retorna el nombre del tipo de plan encontrado
      return nombre_tipo_plan;
    },

    /**Funcion que retorna los datos de un periodo de pago apartir de su id
     *
     * @param {number} id_periodo_pago id del periodo de pago al que se le van a consultar los datos
     * @returns {object} datos sobre el periodo de pago que se consulto
     */
    obtenerDatosPeriodoPago(id_periodo_pago) {
      /**Variable que va retornar el nombre del periodo de pago en caso de ser encontrado */
      var data_periodo_pago = {
        nombre_periodo_pago: "N/a",
        cantidad_meses: 0,
      };

      //Se valida que si hallan periodos de pago
      if (this.periodos_pago.length > 0) {
        //Se itera sobre los periodos de pago existentes
        this.periodos_pago.forEach((periodo_pago) => {
          //Se compara el id del periodo de pago sobre el que se esta iterando con el que fue recibio por los parametros de la funcion
          if (periodo_pago.id == id_periodo_pago) {
            data_periodo_pago.nombre_periodo_pago = periodo_pago.periodo_pago;
            data_periodo_pago.cantidad_meses = periodo_pago.cantidad_meses;
          }
        });
      }
      //Se retorna los datos del periodo de pago que coincidio con ese id
      return data_periodo_pago;
    },

    /**Funcion que retorna los datos de un medio de pago apartir de su id
     *
     * @param {number} id_medio_pago id del medio de pago al que se la buscar los datos
     * @returns {object} datos del medio de pago
     */
    obtenerDatosMedioPago(id_medio_pago) {
      /**Variable que va retornar los datos del medio de pago en el caso de ser encontrado */
      var data_medio_pago = {
        nombre_medio_pago: "N/a",
        debito_automatico: false,
      };

      //Se valida que si hallan medios pago
      if (this.medios_pago.length > 0) {
        //Se itera sobre los medios de pago existentes
        this.medios_pago.forEach((medio_pago) => {
          //Se compara el id del del medio de pago sobre el que se esta iterando con el que recibio por los parametros de la funcion
          if (medio_pago.id_medio_pago == id_medio_pago) {
            data_medio_pago.nombre_medio_pago = medio_pago.v;
            data_medio_pago.debito_automatico = medio_pago.debito_automatico;
          }
        });
      }
      //Se retorna los datos del medio de pago encontrado
      return data_medio_pago;
    },
  },
};
</script>
